/* Общие стили */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none;
}

html, body {
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #1e3c72, #2a5298);
  font-family: 'Arial', sans-serif;
  position: relative;
  overflow: hidden;
}

h1 {
  font-size: 80px;
  color: #fff;
  animation: flicker 2s infinite alternate;
}

h2 {
  font-size: 40px;
  color: #ffcc00;
  margin-top: 20px;
  animation: slideIn 1.5s ease-in-out;
}

.countdown, .request-counter {
  font-size: 30px;
  color: #fff;
  margin-top: 20px;
  animation: pulse 1.5s infinite;
}

.new-year {
  font-size: 80px;
  color: #ffcc00;
  text-shadow: 2px 2px 10px #ff6600, -2px -2px 10px #ff3300;
  animation: celebration 2s infinite alternate;
}

/* Снежинки */
.snowflake {
  position: absolute;
  top: -10%;
  color: #ffffff;
  font-size: 20px;
  opacity: 0.9;
  pointer-events: none;
  animation: fall linear infinite;
}

@keyframes fall {
  to {
    transform: translateY(100vh) rotate(360deg);
  }
}

/* Фейерверки */
.fireworks-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(255, 255, 0, 0.3), rgba(255, 0, 0, 0.5));
  animation: fireworks 2s ease-out forwards;
}

@keyframes fireworks {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
}

/* Конфетти */
.confetti {
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(255, 255, 0, 0.5), rgba(255, 0, 0, 0.7));
  animation: burst 2s ease-out forwards;
}

@keyframes burst {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
}

/* Анимации */
@keyframes flicker {
  0% { opacity: 1; }
  50% { opacity: 0.6; }
  100% { opacity: 1; }
}

@keyframes slideIn {
  0% { transform: translateY(-50px); opacity: 0; }
  100% { transform: translateY(0); opacity: 1; }
}

@keyframes pulse {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.1); }
}

@keyframes celebration {
  0% {
    transform: scale(1);
    text-shadow: 2px 2px 10px #ff6600, -2px -2px 10px #ff3300;
  }
  100% {
    transform: scale(1.2);
    text-shadow: 5px 5px 20px #ffcc00, -5px -5px 20px #ff9900;
  }
}

/* Медиазапросы для адаптивности */

@media screen and (max-width: 768px) {
  h1 {
    font-size: 50px;
  }

  h2 {
    font-size: 30px;
  }

  .countdown, .request-counter {
    font-size: 20px;
  }

  .new-year {
    font-size: 60px; /* Уменьшаем размер шрифта на планшетах */
    text-shadow: 2px 2px 8px #ff6600, -2px -2px 8px #ff3300; /* Уменьшаем тени */
  }

  .snowflake {
    font-size: 15px;
  }
}

@media screen and (max-width: 480px) {
  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 25px;
  }

  .countdown, .request-counter {
    font-size: 18px;
  }

  .new-year {
    font-size: 40px; /* Уменьшаем размер шрифта на мобильных устройствах */
    text-shadow: 1px 1px 6px #ff6600, -1px -1px 6px #ff3300; /* Уменьшаем тени */
  }

  .snowflake {
    font-size: 12px;
  }
}